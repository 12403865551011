import { render, staticRenderFns } from "./HomeHeader.vue?vue&type=template&id=0a970718&scoped=true"
import script from "./HomeHeader.vue?vue&type=script&lang=js"
export * from "./HomeHeader.vue?vue&type=script&lang=js"
import style0 from "./HomeHeader.vue?vue&type=style&index=0&id=0a970718&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a970718",
  null
  
)

export default component.exports