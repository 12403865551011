<template>
	<div class="header-comp">
		<ul>
			<li @click="jumpPage('/main/order/list/send')">
				<div class="left">
					<img src="@/assets/image/home/1.png" alt="" />
				</div>
				<div class="right">
					<div class="tit">待发货订单</div>
					<div class="num">
						<Badge dot :count="0">
							{{ $utils.formatNumberData(orderInfo.sendGoods) }}
						</Badge>
					</div>
				</div>
			</li>
			<li @click="jumpPage('/main/goods/list/inSale')">
				<div class="left">
					<img src="@/assets/image/home/5.png" alt="" />
				</div>
				<div class="right">
					<div class="tit">商品总数</div>
					<div class="num">
						{{ $utils.formatNumberData(orderInfo.shopNum) }}
					</div>
				</div>
			</li>
			<li @click="jumpPage('/main/order/all')">
				<div class="left">
					<img src="@/assets/image/home/6.png" alt="" />
				</div>
				<div class="right">
					<div class="tit">订单数量</div>
					<div class="num">
						{{ $utils.formatNumberData(orderInfo.orderNum) }}
					</div>
				</div>
			</li>
		</ul>
	</div>
</template>

<script>
export default {

	data() {
		return {
			orderInfo: {}
		};
	},
	created() {
		this.getOrderInfo();
	},
	methods: {

		// 首页订单总览
		async getOrderInfo() {
			const result = await this.$api.home.statistics();
			this.orderInfo = result;
		},
		jumpPage(url) {
			if (!url) return;
			console.log(url);
			this.$router.push({ path: url });
		}
	}
};
</script>

<style scoped lang="scss">
.header-comp {
	background-color: #fff;
	border-radius: 2px;
	padding-top: 20px;
	padding-bottom: 20px;

	ul {
		display: flex;

		li {
			flex: 1;
			/*padding-left: 60px;*/
			display: flex;
			align-items: center;
			cursor: pointer;
			justify-content: center;


			&:nth-child(1) {
				border-right: 1px solid #e9edef;
			}

			&:nth-child(2) {
				border-right: 1px solid #e9edef;
			}

			&:hover {
				.right {
					.num {
						color: $data-blue;
					}
				}
			}

			.left {}

			.right {
				padding-left: 14px;

				.tit {
					font-size: 14px;
					line-height: 20px;
					color: #636669;
				}

				.num {
					margin-top: 10px;
					font-family: PingFang SC;
					font-style: normal;
					font-weight: 500;
					font-size: 24px;
					line-height: 29px;
					color: #262b30;

					/deep/ .ivu-badge-dot {
						width: 6px;
						height: 6px;
						top: -3px;
						right: -6px;
					}
				}
			}
		}
	}
}
</style>
